import React, { useEffect, useRef } from 'react';
import TransitionLink from "gatsby-plugin-transition-link";
import { gsap } from 'gsap';
import { setPreset } from '../../three/src/main';
import { Helmet } from "react-helmet"
import * as projectStyles from './project.module.css';

const tl = gsap.timeline();

const Project = (props) => {
    const container = useRef(null);
    const content = useRef(null);
    const back = useRef(null);

    useEffect(() => {
        setPreset(4);
        tl.to(container.current, { opacity: 1, duration: 0.5})
          .to(content.current, { opacity: 1, duration: 1})
          .to(back.current, { opacity: 1, duration: 0.2}, '-=1');
    }, []);

    useEffect(() => {
        if(props.transitionStatus === 'exiting') {
            gsap.to(container.current, { opacity: 0, duration: 1})
        }
    }, [props.transitionStatus]);

    return (
        <>
            <Helmet>
                <title>{`${props.title} | Luka Batista`}</title>
            </Helmet>
            <div className={projectStyles.container} ref={container}>
                <div className={projectStyles.box}>
                    <div className={projectStyles.titleBox}>
                        <h1 className={projectStyles.title}>
                            {props.title}
                        </h1>
                        <div className={projectStyles.subtitleBox}>
                            <h2 className={projectStyles.subtitle}>
                                {props.subtitle}
                            </h2>
                            <div className={projectStyles.date}>
                                {props.date}
                            </div>      
                        </div>
                    </div>
                    <div className={projectStyles.content} ref={content}>
                        {props.content}
                    </div>
                    <div className={projectStyles.backContainer}>
                        <div className={projectStyles.back} ref={back}>
                            <TransitionLink
                                to="/works"
                                exit={{
                                    length: 1,
                                }}
                                entry={{
                                }}
                            >
                                see other works ↵
                            </TransitionLink>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Project;