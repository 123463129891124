import React from 'react';
import Project from './project';

const Rasterhang = ({transitionStatus}) => {
    return (
        <>
            <Project 
                transitionStatus={transitionStatus}
                title="Rasterhang"
                subtitle="music video"
                date="2021"
                content={
                    <>
                        <div style={{height:0, position: 'relative', paddingBottom: '56.25%', marginBottom: '40px'}}>
                            <iframe
                                title="Rasterhang"
                                style={{width: '100%', height: '100%', top: 0, left: 0, position: 'absolute'}}
                                src={"https://www.youtube.com/embed/c3mXlibyfFk?modestbranding=1?autohide=1&showinfo=0"}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                frameBorder="0"
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                allowFullScreen
                            /> 
                        </div>
                        <p>Electric guitar, synth and sliced drum breaks. Audio-reactive laser visuals.</p>
                        <p>Projected using 2 lasers doing raster scanning of mostly Voronoi noise. Each laser is controlled with 5 channels of audio as control voltage signals, 
                            2 for the X/Y positions of the laser beam and 3 for switching R/G/B diodes on and off.
                        <br/>Control software built using Cycling ‘74 Max / Gen~ / Jitter and Javascript.</p>
                        <p>Released on September 22, 2021.
                        <br/>Mastering by Joel Krozer, Six Bit Deep.</p>
                        <p>Also available on <a rel="noreferrer" target="_blank" href="https://lukabatista.bandcamp.com/track/rasterhang">Bandcamp
                        </a> and <a rel="noreferrer" target="_blank" href="https://open.spotify.com/album/3iQMACIDEOgOa8TM5hK5OQ">music streaming platforms</a>.</p>
                    </>
                }
            />
        </>
    );
}

export default Rasterhang;